import { defineStore } from 'pinia'
import { use_tenant } from '@public/stores/tenant_store'

import Vue from 'vue'

export const use_public_prescriptions = defineStore('public_prescriptions', {
  state: () => ({
    token: null,
    order: null,
    settings: null,

    gym: null,
    smcb_api_base_url: null,
  }),

  actions: {
    async load() {
      const { data } = await Vue.smcb_axios.get(`${use_tenant().api_home_url}/prescriptions/public`)
      const tenant = use_tenant()
      tenant.home = data.gym
      tenant.settings = data.gym_settings
      tenant.is_german_pharma = data.integration_options.is_german_pharma_location
      this.settings = data.settings
    },

    async fetch_prescriptions_settings() {
      const { data } = await Vue.smcb_axios.get(`${use_tenant().api_home_url}/prescriptions/settings`)
      this.settings = data
    },

    async upload_prescriptions(formdata) {
      try {
        const response = await Vue.smcb_axios.post(`${use_tenant().api_home_url}/prescriptions/public`, formdata)
        return response
      } catch (e) {
        console.error(e)
        return e
      }
    },

    async fetch_prescription_order() {
      const { data } = await Vue.smcb_axios.get(`${use_tenant().api_home_url}/prescriptions/public/${this.token}`)
      this.order = data.order
    },

    async delete_prescription() {
      const { data } = await Vue.smcb_axios.delete(`${use_tenant().api_home_url}/prescriptions/public/${this.token}`)
      this.order = data.order
    },

    delete_url(token) {
      return `${window.location.pathname}/review/${token}`
    },
  },
})
