import Vue from 'vue'
import SignatureEmitter from '@public/signatures/lib/signature_emitter'

import { eventBus } from '@core/shared/event_bus'
import { SHOW_LOADING } from '@public/signatures/components/signature_loader.vue'

import { defineStore } from 'pinia'
import { use_tenant } from '@public/stores/tenant_store'

function get_sign_state(sign_array) {
  const sign_states = ['signed', 'skip']
  const exists = sign_array?.find(elem => {
    return elem.data !== undefined
  })
  return exists?.data === undefined ? sign_states[1] : sign_states[0]
}

export const use_public_signatures = defineStore('public_signatures', {
  state: () => ({
    doc_uuid: null,
    participant: {},
    pdf_width: 0,
    pdf: null,
    scroll_pos: null,
    service: {},
    ui_components: { signatures: [], checkboxes: [], textboxes: [] },
    step: 'sign',
  }),

  actions: {
    set_sign(sign) {
      const signature_index = this.ui_components.signatures.findIndex(s => s.id === sign.id)
      if (signature_index > -1) {
        Vue.set(this.ui_components.signatures[signature_index], 'data', sign.data)
      }
    },

    set_skip_customer_signatures() {
      this.ui_components.signatures
        ?.filter(signature => signature.signatory === 'participant' && !signature.data)
        .forEach(sign => {
          const signature_index = this.ui_components.signatures.findIndex(s => s.id === sign.id)
          Vue.set(this.ui_components.signatures[signature_index], 'skip', true)
        })
      this.step = 'staff'
    },

    set_data_api_compatible() {
      this.ui_components.signatures?.forEach(sign => {
        if (sign.data) {
          const data = sign.data.split(',')
          sign.data = data[1]
        }
      })
    },

    async fetch_document_file(pstoken) {
      const url = `${use_tenant().api_home_url}/signatures/sign/${this.participant.id}/${this.doc_uuid}/document/${pstoken}`
      const { data } = await Vue.smcb_axios.get(url)
      const encoded_uri = `data:application/pdf; base64,${encodeURI(data.pdf)}`
      this.pdf = encoded_uri
      this.ui_components = data.ui_components
    },

    async create_sign() {
      this.set_data_api_compatible()
      const payload = { components: this.ui_components, state: get_sign_state(this.ui_components.signatures) }
      const url = `${use_tenant().api_home_url}/signatures/sign/${this.participant.id}/${this.doc_uuid}`
      try {
        await Vue.smcb_axios.post(url, payload)
      } catch (e) {
        SignatureEmitter.set_signature_processing_error()
      }
    },

    async send_signatures() {
      this.set_loader(true)
      try {
        await this.create_sign()
        this.reset_page()
      } catch (e) {
        Vue.$vl_utils.show_error_alert(Vue.$i18n.t('signature.alert.upload_failed'), e)
      }
      this.set_loader(false)
    },

    set_loader(loading) {
      eventBus.$emit(SHOW_LOADING, loading)
    },

    reset_page() {
      window.location = `/${use_tenant().home.slug}/signatures/service`
    },

    async commit_textbox(id, value) {
      const textbox_index = this.ui_components.textboxes.findIndex(t => t.field_id === id)
      if (textbox_index > -1) {
        Vue.set(this.ui_components.textboxes[textbox_index], 'value', value)
      }
    },

    async commit_checkbox(id, value) {
      const checkbox_index = this.ui_components.checkboxes.findIndex(c => c.field_id === id)
      if (checkbox_index > -1) {
        Vue.set(this.ui_components.checkboxes[checkbox_index], 'checked', value)
      }
    },
  },

  getters: {
    get_gym_path() {
      return `${window.location.protocol}//${window.location.host}/${I18n.locale}`
    },

    get_sign_by_id: state => id => {
      return state.ui_components.signatures.find(signature => signature.id === id)
    },

    done: state => {
      return !state.ui_components.signatures?.length || state.ui_components.signatures.find(signature => !signature.data && !signature.skip) === undefined
    },

    customer_is_done: state => {
      return state.ui_components.signatures?.find(signature => signature.signatory === 'participant' && !signature.data && !signature.skip) === undefined
    },

    employee_has_signed_once: state => {
      return state.amount_of_signatures_signed_by_employee > 0
    },

    customer_has_signed_once: state => {
      return state.ui_components.signatures?.find(signature => signature.signatory === 'participant' && signature.data) !== undefined
    },

    amount_of_signatures_signed_by_customer: state => {
      return state.ui_components.signatures?.filter(signature => signature.signatory === 'participant' && signature.data).length
    },

    total_signatures_needed_from_customer: state => {
      return state.ui_components.signatures?.filter(signature => signature.signatory === 'participant').length
    },

    amount_of_signatures_signed_by_employee: state => {
      return state.ui_components.signatures?.filter(signature => signature.signatory === 'manager' && signature.data).length
    },

    total_signatures_needed_from_employee: state => {
      return state.ui_components.signatures?.filter(signature => signature.signatory === 'manager').length
    },

    employee_must_sign: state => {
      return state.total_signatures_needed_from_employee > 0
    },

    has_signatures: state => {
      return state.ui_components.signatures?.length > 0
    },

    has_inputs: state => {
      return state.ui_components.checkboxes?.length + state.ui_components.textboxes?.length > 0
    },

    inputs_only: state => {
      return state.has_inputs && !state.has_signatures
    },
  },
})
