import Vue from 'vue'
import { defineStore } from 'pinia'

import { use_tenant } from '@public/stores/tenant_store'

export const use_public_rental = defineStore('public_rental', {
  state: () => ({
    models: {},
  }),

  actions: {
    async load() {
      const url = `${use_tenant().api_home_url}/rental/populate`
      const { data } = await Vue.smcb_axios.get(url)
      this.models = Object.fromEntries(data.models.map(m => [m.id, m]))
    },

    async load_one_uuid(model_uuid) {
      const url = `${use_tenant().api_home_url}/rental/rental_models/uuid/${model_uuid}`
      const { data } = await Vue.smcb_axios.get(url)
      Vue.set(this.models, data.model.id, data.model)
    },
  },
})
